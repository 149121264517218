module.exports = [{
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingId": "UA-143010757-1",
    "anonymize": true,
    "head": true,
    "sampleRate": 100,
    "siteSpeedSampleRate": 100,
    "cookieDomain": "champdefleurs.fr"
  }
}, {
  plugin: require('/opt/build/repo/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}, {
  plugin: require('/opt/build/repo/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];